import { action, makeObservable, observable } from 'mobx';

import type { IBoardingData } from '../interfaces/IBoardingData';

class SignerStore implements IBoardingData {
  name: string = '';

  ssn: string = '';

  dob: Date = new Date(
    new Date().getFullYear() - 19,
    new Date().getMonth(),
    new Date().getDay()
  );

  phone: string = '';

  email: string = '';

  address: string = '';

  address1: string = '';

  state: string = '';

  country: string = '';

  city: string = '';

  zip: string = '';

  acceptance: boolean = false;

  signedDocumentReference: string = '';

  errors: any = {};

  constructor() {
    makeObservable(this, {
      state: observable,
      country: observable,
      city: observable,
      zip: observable,
      acceptance: observable,
      name: observable,
      ssn: observable,
      dob: observable,
      phone: observable,
      email: observable,
      address: observable,
      address1: observable,
      errors: observable,
      signedDocumentReference: observable,
      setProp: action,
      setError: action,
    });
  }

  /**
   * It takes a key of the SignerStore interface and a value of any type, and sets the value of the key
   * to the value
   * @param {K} name - The name of the property to set.
   * @param {any} value - any - The value to set the property to.
   */
  setProp<K extends keyof SignerStore>(name: K, value: any): void {
    this[name] = value;
  }

  /**
   * It takes a key of the SignerStore interface and returns the value of that key
   * @param {K} field - K - The field we want to get the value of.
   * @returns The value of the field.
   */
  getValue<K extends keyof SignerStore>(field: K): any {
    return this[field];
  }

  /**
   * "Set the value of the property with the name of the first argument to the value of the second
   * argument."
   *
   * The first argument is a string that must be a key of the SignerStore interface. The second
   * argument is a boolean
   * @param {K} name - K - The name of the property to set the error for.
   * @param {boolean} value - The value of the input field.
   */
  setError<K extends keyof SignerStore>(name: K, value: boolean): void {
    this.errors[name] = value;
  }

  /**
   * Get the error for the given key from the errors object.
   * @param {K} name - The name of the field to get the error for.
   * @returns The error message for the given key.
   */
  getError<K extends keyof SignerStore>(name: K): void {
    return this.errors[name];
  }

  /**
   * It returns an object with a property called signer, which is an object with properties that match
   * the properties of the class
   * @returns An object with a signer property that has the name, ssn, dob, phone, email, address,
   * address1, state, country, city, and zip properties.
   */
  getJson(forPDF: boolean = false): any {
    const signer: any = {
      name: this.name,
      ssn: this.ssn,
      dob: this.dob,
      phone: this.phone,
      email: this.email,
      address: this.address,
      address1: this.address1,
      state: this.state,
      country: this.country,
      city: this.city,
      zip: this.zip,
    };
    if (!forPDF) {
      signer.signedDocumentReference = this.signedDocumentReference;
      signer.acceptance = this.acceptance;
      if(this.ssn.indexOf('X') > -1 || this.ssn.indexOf('x') > -1) {
        signer.ssn = null;
      }
    }
    return {
      signer,
    };
  }
}
export default SignerStore;
