import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Transition } from 'semantic-ui-react';

import { renderInput } from '../utils/input-tools';
import SignerSelection from './SignerSelection';

class Signer extends React.Component<any, any> {
  signerData: Array<any> = new Array<any>();

  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
    };
    this.signerData = props.signerData;
  }

  componentDidMount(): void {
    this.setState({
      visible: true,
    });
  }

  render(): React.ReactNode {
    return (
      <div key={'signer-component'}>
        <Transition
          key={'signer-component-tx'}
          duration={400}
          visible={this.state?.visible}
        >
          <Grid key={'signer-component-grid'} doubling columns={2}>
            <Grid.Column key={'signer-component-grid-column'} width={16}>
              {
                <SignerSelection
                  owners={this.props.owners}
                  contacts={this.props.contacts}
                  showOwners={this.props.showOwners}
                />
              }
            </Grid.Column>
            {Object.entries(this.signerData || []).filter(([key, value]) => key !== 'visible' && value?.visible).map(
              (signer: any, index: number): any => {
                const inputName = signer[0];
                const signerItem = this.signerData[inputName];
                if (inputName === undefined || inputName === '') return null;
                if (signerItem === undefined || signerItem === '') return null;
                const fieldMetadata = {
                  name: inputName,
                  storeName: inputName,
                  label: signerItem?.label,
                  index,
                  restrictedDob: new Date(
                    new Date().getFullYear() - 18,
                    new Date().getMonth(),
                    new Date().getDay()
                  ),
                  required: signerItem?.required,
                  value: this.props.boarding.signer[inputName],
                  boarding: this.props.boarding,
                  identifyField: '',
                  locationFields: [
                    'address',
                    'address1',
                    'city',
                    'state',
                    'zip',
                    'country',
                  ],
                  component: 'signer',
                };
                if (inputName === 'address' || inputName === 'name') {
                  return (
                    <Grid.Column
                      key={`signer-component-grid-field${index}`}
                      floated={
                        index === 0 || index % 2 === 0 ? 'left' : 'right'
                      }
                      width={16}
                    >
                      {renderInput(signerItem.type, fieldMetadata)}
                    </Grid.Column>
                  );
                }
                return (
                  <Grid.Column
                    key={`signer-component-grid-field${index}`}
                    floated={index === 0 || index % 2 === 0 ? 'left' : 'right'}
                    width={8}
                  >
                    {renderInput(signerItem.type, fieldMetadata)}
                  </Grid.Column>
                );
              }
            )}
          </Grid>
        </Transition>
      </div>
    );
  }
}

export default inject('boarding')(observer(Signer));
