import { action, computed, makeObservable } from 'mobx';

import type { IOwner } from '../interfaces/IOwner';
import { OWNER_STATUS } from '../interfaces/IOwner';
import AbstractContainerStore from './AbstractContainerStore';

class OwnerStore extends AbstractContainerStore<IOwner> {
  constructor() {
    super();
    makeObservable(this, {
      setProp: action,
      addOwner: action,
      setError: action,
      overflowPercent: computed,
    });
    // this.addOwner();
  }

  get overflowPercent(): boolean {
    const totalPercent = this.elementsList
      .map((owner: IOwner) => {
        return +owner.ownerpercent;
      })
      .reduce((acc, element) => acc + element, 0);
    return totalPercent > 100;
  }

  addOwner(
    ownername: string = '',
    ownertitle: string = '',
    ownerpercent: number = 0,
    ownerssn: string = '',
    ownerdob: Date | null = null,
    ownerphone1: string = '',
    ownerphone2: string = '',
    owneremail: string = '',
    ownerdriver: string = '',
    odriverstate: string = '',
    oaddress: string = '',
    oaddress1: string = '',
    ostate: string = '',
    ocountry: string = '',
    ocity: string = '',
    ozip: string = '',
    status: OWNER_STATUS = OWNER_STATUS.new
  ): void {
    const owner: IOwner = {
      id: this.getNextId(),
      ownername,
      ownertitle,
      ownerpercent,
      ownerssn,
      ownerdob,
      ownerphone1,
      ownerphone2,
      owneremail,
      ownerdriver,
      odriverstate,
      oaddress,
      oaddress1,
      ostate,
      ocountry,
      ocity,
      ozip,
      errors: {},
      status,
    };
    this.add(owner);
  }

  /**
   * It takes a key of type K, which is a key of type IOwner, and a value of type V, which is a value
   * of type IOwner[K], and a metadata object, and sets the value of the key of the element at the
   * index of the metadata object to the value
   * @param {K} name - The name of the property to set.
   * @param {V} value - The value to set the property to.
   * @param metadata - Record<string, string | Object>
   */
  setProp<K extends keyof IOwner, V extends IOwner[K]>(
    name: K,
    value: V,
    metadata: Record<string, string | Object>
  ): void {
    const index = metadata.index || 0;
    this.elementsList[+index][name] = value;
    this.elementsList[+index].status = OWNER_STATUS.posted;
  }

  /**
   * It takes a name, a value, and a metadata object, and sets the error value of the element at the
   * index specified in the metadata object
   * @param {K} name - The name of the field that has an error.
   * @param {boolean} value - The value of the field.
   * @param metadata - {
   */
  setError<K extends keyof IOwner>(
    name: K,
    value: boolean,
    metadata: Record<string, string | Object>
  ): void {
    const index = metadata.index || 0;
    this.elementsList[+index].errors[name] = value;
  }

  /**
   * It returns the error of the element at the index.
   * @param {K} name - The name of the field error.
   * @param metadata - Record<string, string | Object>
   * @returns A boolean value.
   */
  getError<K extends keyof IOwner>(
    name: K,
    metadata: Record<string, string | Object>
  ): boolean {
    const index = metadata.index || 0;
    return this.elementsList[+index]?.errors[name];
  }

  setPosted(): void {
    // eslint-disable-next-line no-return-assign
    this.elementsList.map((element) => (element.status = OWNER_STATUS.posted));
  }

  /**
   * It takes an array of objects, and returns an array of objects with the same keys, but without the
   * keys 'id' and 'errors'
   * @returns An array of objects with the keys of the elementsList objects, minus the id and errors
   * keys.
   */
  getJson(forPDF: boolean = false): any {
    const owners = this.elementsList.reduce((acc: Array<any>, el: any): any => {
      const owner = Object.fromEntries(Object.entries(el || []));
      delete owner.id;
      delete owner.errors;
      delete owner.status;
      if(!forPDF && typeof owner?.ownerssn === 'string' && (owner.ownerssn.indexOf('X') > -1 || owner.ownerssn.indexOf('x') > -1)){
        owner.ownerssn = null;
      }
      return acc.concat(owner);
    }, []);
    return {
      ownership: owners,
    };
  }
}
export default OwnerStore;
