import { inject, observer } from 'mobx-react';
import React from 'react';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Button, Card, Header, Icon, Input, Modal } from 'semantic-ui-react';

// import TextInput from './TextInput';
import SignInput from './inputs/SignInput';

// TODO: Split in 2 components, one to draw and another for type.
class TermsConditions extends React.Component<any, any> {
  termsConditionsData: Array<any> = new Array<any>();

  constructor(props: any) {
    super(props);
    this.state = {
      openModalSignature: false,
      openModalTypeSignature: false,
      checkAccepted: [],
      termsAccepted: false,
      notSigned: false,
      typeSign: '',
    };
    this.termsConditionsData = props.termsConditionsData;
  }

  handlerChange(e: any): void {
    this.setState({
      typeSign: e.target.value,
    });
  }

  componentDidMount(): void {
    const signature = this.props.boarding.attachments.elementsList.filter(
      (element: any) => {
        const { fileDescriptor } = element;
        return (
          JSON.parse(fileDescriptor === '' ? '{}' : fileDescriptor)?.type ===
          'signature'
        );
      }
    );
    if (
      this.props?.termsConditionsData?.length >
      this.props.boarding.termsCondition.elementsList.length
    ) {
      this.props.termsConditionsData?.map((term: any) => {
        this.props.boarding.termsCondition.addTerm(
          term.label,
          term.value,
          false
        );
      });
    }
    this.setState({ notSigned: signature.length === 0 });
  }

  handleAcceptClick = (e: any, index: number): void => {
    e.preventDefault();
    this.props.boarding.termsCondition.acceptTerm(index);
    const href = e.target.getAttribute('href');
    if (href === null) {
      window.open('/404', '_blank');
    } else {
      window.open(href, '_blank');
    }
  };

  handleAcceptCheckClick = (e: any): void => {
    const validTabs = [false, false, false, false, false];
    for (let tab = 0; tab < 5; tab++) {
      if (this.props.boarding.isValidTab(tab)) {
        validTabs[tab] = true;
      } else {
        validTabs[tab] = false;
      }
    }
    if (e.target.checked) {
      if (!this.props.boarding.termsCondition.completeAccepted) {
        e.preventDefault();
        toast.error(
          'You must click and review link(s) above before agreeing.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'toast-error-container',
          }
        );
        this.setState({ termsAccepted: false });
      } else if (
        validTabs.includes(false) ||
        !this.props.boarding.termsCondition.completeAccepted
      ) {
        const index = validTabs.findIndex((tab: any) => tab === false);
        if (index > -1) {
          this.props.gotoNextTabAndSubmitOnlyCheck(4, true);
          this.props.gotoTabWithError(index);
          toast.error(
            "The application terms can't be accepted while there are invalid fields.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: 'toast-error-container',
            }
          );
        }
      }
      else {
        this.setState({ termsAccepted: true }, () => {
          this.props.boarding.signer.setProp('acceptance', true);
          // this.props.generatePDF();
        });
      }
    } else {
      this.props.boarding.signer.setProp('acceptance', false);
      this.setState({ termsAccepted: false });
    }
  };

  setOpen(toggle: boolean, type = 'digital'): void {
    const validTabs = [false, false, false, false, false];
    for (let tab = 0; tab < 5; tab++) {
      if (this.props.boarding.isValidTab(tab)) {
        validTabs[tab] = true;
      } else {
        validTabs[tab] = false;
      }
    }
    if (
      validTabs.includes(false) ||
      !this.props.boarding.termsCondition.completeAccepted
    ) {
      toast.error(
        "The application can't be signed while there are invalid fields or you have not accepted the terms.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'toast-error-container',
        }
      );
    } else if (type === 'digital')
      this.setState({ openModalSignature: toggle });
    else this.setState({ openModalTypeSignature: toggle });
  }

  saveTypeSign(): void {
    this.props.boarding.termsCondition.signTerms(this.state.typeSign);
    this.setOpen(false, 'typing');
    const existAttachemt =
      this.props.boarding.attachments.getByType('signature');
    if (existAttachemt >= 0) {
      this.props.boarding.attachments.remove(existAttachemt);
    }
    this.props.boarding.attachments.addAttachment(
      'image/png',
      'signature',
      JSON.stringify({
        type: 'signature',
        signature: this.state.typeSign,
      }),
      '',
      ''
    );
  }

  getSignModal(): React.ReactNode {
    return (
      <Modal
        closeIcon
        open={this.state.openModalSignature}
        onClose={(): void => this.setOpen(false)}
        onOpen={(): void => this.setOpen(true)}
      >
        <Header icon="signup" content="Draw your signature" />
        <Modal.Content>
          <div className="sigPadContainer">
            <SignInput />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={(): void => this.setOpen(false)}>
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  getTypeSignModal(): React.ReactNode {
    return (
      <Modal
        closeIcon
        open={this.state.openModalTypeSignature}
        onClose={(): void => this.setOpen(false, 'typing')}
        onOpen={(): void => this.setOpen(true, 'typing')}
      >
        <Header icon="keyboard outline" content="Type your signature" />
        <Modal.Content>
          <div className="sigPadContainer">
            <Input
              icon="tags"
              iconPosition="left"
              label={{
                tag: true,
                content: this.props.boarding.signer.name,
              }}
              labelPosition="right"
              placeholder="Enter sign"
              onChange={(e: any): void => this.handlerChange(e)}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="blue" onClick={(): void => this.saveTypeSign()}>
            <Icon name="save" /> Save
          </Button>
          <Button
            color="red"
            onClick={(): void => this.setOpen(false, 'typing')}
          >
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  render(): React.ReactNode {
    const infoTheme = {
      fontSize: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    return (
      <>
        <div className="mb-4">
          <>
            <h5 className="mt-2 text-center termscondition-container">
              Confirmation & Attestation
            </h5>
            <small className="small mb-4 dark-grey text-center termscondition-container">
              Please Review, and Print, or Save the documents provided in links
              below.
            </small>
          </>
          <>
            <Card fluid className="card card-in mb-3 termscondition-container">
              <Card.Header>
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    margin: '0.5em',
                  }}
                >
                  <div className="col-4 col-sm-2 col-md-1" style={infoTheme}>
                    {(this.props.boarding.signer.acceptance === true && (
                      <BsFillExclamationCircleFill color="limegreen" />
                    )) || <BsFillExclamationCircleFill color="red" />}
                  </div>
                  <div className="col-8 col-sm-10 col-md-11">
                    <p>
                      By clicking below I agree and hereby confirm to have Read,
                      Agreed and Consented to the
                      {this.termsConditionsData?.map(
                        (term: any, index: number): any => {
                          return (
                            <span key={`term-condition-key-${index}`}>
                              <span className="text-dark">
                                {' '}
                                <strong>{term.label}</strong>
                              </span>{' '}
                              <a
                                style={{
                                  textDecoration: 'none',
                                }}
                                rel="noreferrer"
                                onClick={(e: any): void =>
                                  this.handleAcceptClick(e, index)
                                }
                                target="_blank"
                                data-
                                href={term.value}
                              >
                                (CLICK HERE)
                              </a>
                              {index === this.termsConditionsData.length - 2
                                ? ' and '
                                : ', '}
                            </span>
                          );
                        }
                      )}
                      <span>
                        {' '}
                        provided. Please Review and Save these documents
                        provided in links above.
                      </span>
                    </p>
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <div
                  style={{ display: 'flex', gap: '1rem' }}
                  className="icheck-primary"
                >
                  <div className="col-4 col-sm-2 col-md-1" style={infoTheme}>
                    <input
                      style={{
                        backgroundColor: '#10A0E3',
                        borderColor: '#10A0E3',
                        minHeight: '22px',
                        marginTop: '6px!important',
                        marginBottom: '6px!important',
                        paddingLeft: 0,
                        width: '22px',
                        height: '22px',
                      }}
                      checked={this.props.boarding.signer.acceptance}
                      type="checkbox"
                      name="acceptance"
                      id="acceptance"
                      onClick={this.handleAcceptCheckClick}
                    />
                  </div>
                  <div className="col-8 col-sm-10 col-md-11">
                    <label style={{ fontSize: '12px' }} htmlFor="acceptance">
                      I Agree to the above information & I attest that the
                      information provided in this application is correct to the
                      best of my knowledge as an authorized signer for this
                      business.
                    </label>
                  </div>
                </div>
              </Card.Content>
            </Card>
          </>
          <div
            className="buttons-signer"
            style={{ height: '100%', display: 'none' }}
          >
            <Button.Group fluid size="medium" style={{ height: '100%' }}>
              <Button
                primary
                icon="signup"
                content={
                  <span className="span-sign-btn">Draw your signature</span>
                }
                onClick={(): void => this.setOpen(true)}
              />
              <Button.Or />
              <Button
                positive
                icon="keyboard outline"
                onClick={(): void => this.setOpen(true, 'typing')}
                content={
                  <span className="span-sign-btn">Type your signature</span>
                }
              />
            </Button.Group>
          </div>
          {this.getSignModal()}
          {this.getTypeSignModal()}
        </div>
      </>
    );
  }
}

export default inject('boarding')(observer(TermsConditions));
