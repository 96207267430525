/* eslint-disable no-debugger */
import { action, makeObservable, observable } from 'mobx';

import BoardingService from '../services/BoardingService';
import AttachmentStore from './AttachmentStore';
import BankingStore from './BankingStore';
import BusinessStore from './BusinessStore';
import ContactStore from './ContactStore';
import OwnerStore from './OwnerStore';
import ProcessingStore from './ProcessingStore';
import ServicesStore from './ServicesStore';
import SignerStore from './SignerStore';
import TermsConditionStore from './TermsConditionStore';
// import { IOwner } from '../interfaces/IOwner';

/* Creating a class called BoardingStore. */
class BoardingStore {
  nextClicked: boolean = false;

  boardingService: BoardingService;

  owners: OwnerStore = new OwnerStore();

  signer: SignerStore = new SignerStore();

  contacts: ContactStore = new ContactStore();

  attachments: AttachmentStore = new AttachmentStore();

  businessDetails: BusinessStore = new BusinessStore();

  banking: BankingStore = new BankingStore();

  processing: ProcessingStore = new ProcessingStore();

  services: ServicesStore = new ServicesStore();

  termsCondition: TermsConditionStore = new TermsConditionStore();

  multipleOwners: boolean = false;

  multipleContacts: boolean = false;

  minimumDocuments: number = 0;

  uploadDocuments: boolean = true;

  validOwner: boolean = true;

  fileExceededSize: boolean = false;

  recipientEmail: string = '';

  signerSelected: Object | any = { id: -1, type: null };

  error_attachments: boolean = false;

  constructor() {
    makeObservable(this, {
      saveApplication: action,
      boardingService: observable,
      owners: observable,
      signer: observable,
      contacts: observable,
      attachments: observable,
      businessDetails: observable,
      banking: observable,
      processing: observable,
      services: observable,
      termsCondition: observable,
      nextClicked: observable,
      multipleOwners: observable,
      multipleContacts: observable,
      minimumDocuments: observable,
      uploadDocuments: observable,
      validOwner: observable,
      fileExceededSize: observable,
      recipientEmail: observable,
      signerSelected: observable,
      isValidTab: action,
      setNextClicked: action,
      setMultiOwner: action,
      setMultiContact: action,
      setMinDocument: action,
      setFileExceededSize: action,
      setSignerSelected: action,
      setErrorAttachment: action,
      setUploadDocuments: action,
      setValidOwner: action,
    });
    this.boardingService = new BoardingService(
      process.env.REACT_APP_URL_API || ''
    );
  }

  setSignerSelected(signer: any): void {
    this.signerSelected.id = signer.id;
    this.signerSelected.type = signer.type;
  }

  clearSignerSelected(): void {
    this.signerSelected = { id: -1, type: null };
  }

  setMultiOwner(value: boolean): void {
    this.multipleOwners = value;
  }

  setMultiContact(value: boolean): void {
    this.multipleContacts = value;
  }

  setMinDocument(value: number): void {
    this.minimumDocuments = value;
  }

  setUploadDocuments(value: boolean): void {
    this.uploadDocuments = value;
  }

  setValidOwner(value: boolean): void {
    this.validOwner = value;
  }

  setFileExceededSize(value: boolean): void {
    this.fileExceededSize = value;
  }

  setErrorAttachment(value: boolean): void {
    this.error_attachments = value;
  }

  atLeastDocuments(): boolean {
    if (!this.uploadDocuments) return true;
    return (
      this.attachments.elementsList.filter((element: any) => {
        const { fileDescriptor } = element;
        return (
          JSON.parse(fileDescriptor === '' ? '{}' : fileDescriptor)?.type !==
            'signature' &&
          (element.fContent !== '' || element?.zipName !== '')
        );
      }).length >= this.minimumDocuments
    );
  }

  /**
   * It copies the address of the business to the address of the owner
   * @param {number} id - number - the id of the owner you want to copy the address from
   */
  copyAddressFromBusiness(id: number): void {
    this.owners.elementsList[id].oaddress = this.businessDetails.baddress;
    this.owners.elementsList[id].oaddress1 = this.businessDetails.baddress1;
    this.owners.elementsList[id].ostate = this.businessDetails.bstate;
    this.owners.elementsList[id].ocountry = this.businessDetails.bcountry;
    this.owners.elementsList[id].ocity = this.businessDetails.bcity;
    this.owners.elementsList[id].ozip = this.businessDetails.bzip;
  }

  copyLegalName(): void {
    this.businessDetails.sameTaxFillNameAsLegalName();
  }

  onCopyOwnerInfo(contactId: any): void {
    this.contacts.elementsList[contactId].contactName =
      this.owners.elementsList[0].ownername;
    this.contacts.elementsList[contactId].contactEmail =
      this.owners.elementsList[0].owneremail;
    this.contacts.elementsList[contactId].contactTitle =
      this.owners.elementsList[0].ownertitle;
    this.contacts.elementsList[contactId].contactPhone =
      this.owners.elementsList[0].ownerphone1;
  }

  /**
   * If the tab is 0, return true if the businessDetails object has no errors, or if the
   * businessDetails object has no errors that are true. If the tab is 1, return true if the owners
   * object has no errors, or if the owners object has no errors that are true. If the tab is 2, return
   * true if the processing object has no errors, or if the processing object has no errors that are
   * true. If the tab is 3, return true if the services object has no errors. If the tab is 4, return
   * true if the depositAccount object has no errors, or if the depositAccount object has no errors
   * that are true, and if the withdrawalAccount object has no errors, or if the withdrawalAccount
   * object has no errors that are true.
   * @param {number} tab - number - the tab number that you want to check
   * @returns return (
   *     Object.keys(this.banking.accountsInfo.depositAccount.errors).length !== 0
   *     && Object.values(this.banking.accountsInfo.depositAccount.errors).filter((error: any) => error
   * === true).length === 0
   *   ) &&
   *     (
   *       Object.keys(this
   */
  isValidTab(tab: number): boolean {
    if (tab === 0) {
      return (
        Object.keys(this.businessDetails.errors).length === 0 ||
        Object.values(this.businessDetails.errors).filter(
          (error: any) => error === true
        ).length === 0
      );
    }
    if (tab === 1) {
      const hasNoErrors = (
        elementsList: { errors: Record<string, boolean> }[]
      ): boolean => {
        return elementsList
          .map((element) => Object.values(element.errors))
          .filter((values) => values.length !== 0)
          .every((errors) => !errors.includes(true));
      };

      if (this.validOwner) {
        return (
          hasNoErrors(this.owners.elementsList) &&
          hasNoErrors(this.contacts.elementsList)
        );
      }

      return hasNoErrors(this.contacts.elementsList);
    }
    if (tab === 2) {
      return (
        Object.keys(this.processing.errors).length === 0 ||
        Object.values(this.processing.errors).filter(
          (error: any) => error === true
        ).length === 0
      );
    }
    if (tab === 3) {
      return Object.keys(this.services.errors).length === 0;
    }
    if (tab === 4) {
      return (
        this.atLeastDocuments() &&
        Object.keys(this.banking.accountsInfo.depositAccount.errors).length !==
          0 &&
        Object.values(this.banking.accountsInfo.depositAccount.errors).filter(
          (error: any) => error === true
        ).length === 0 &&
        Object.keys(this.banking.accountsInfo.withdrawalAccount.errors)
          .length !== 0 &&
        Object.values(
          this.banking.accountsInfo.withdrawalAccount.errors
        ).filter((error: any) => error === true).length === 0 &&
        (Object.keys(this.signer.errors).length === 0 ||
        (Object.keys(this.signer.errors).length !== 0 &&
        Object.values(this.signer.errors).filter((error: any) => error === true).length === 0))
      );
    }
    return false;
  }

  /**
   * This function sets the value of the nextClicked variable to the value passed in
   * @param {boolean} value - The value to set the nextClicked variable to.
   */
  setNextClicked(value: boolean): void {
    this.nextClicked = value;
  }

  /**
   * It returns a JSON object with all the data from the form.
   * @returns The getData() method is returning an object that contains all the data from the form.
   */
  getData(
    orgID: any,
    linkId: any,
    signatureRequestId: string,
    email: string
  ): any {
    const businessDetails = Object.fromEntries(
      Object.entries(this.businessDetails || [])
    );
    delete businessDetails.errors;
    delete businessDetails.sameBusinessAddress;

    const processing = Object.fromEntries(
      Object.entries(this.processing || [])
    );
    delete processing.errors;
    if (signatureRequestId !== '') {
      this.signer.setProp('signedDocumentReference', signatureRequestId);
    }
    const signer = Object.fromEntries(
      Object.entries(this.signer.getJson() || [])
    );
    delete signer.errors;
    if(businessDetails.ein.indexOf('X') > -1 || businessDetails.ein.indexOf('x') > -1){
      businessDetails.ein = null;
    }
    return {
      ...businessDetails,
      ...this.contacts.getJson(),
      ...this.owners.getJson(),
      ...this.banking.getJson(),
      ...processing,
      ...this.attachments.getJson(),
      ...this.services.getJson(),
      ...signer,
      orgId: orgID,
      templateId: linkId,
      recipientEmail: email || '',
      recipientEmailNotification: email ? true : false,
    };
  }

  getDataToPDF(orgID: any, linkId: any, templateID: string): any {
    const businessDetails = Object.fromEntries(
      Object.entries(this.businessDetails || [])
    );
    delete businessDetails.errors;
    delete businessDetails.sameBusinessAddress;

    const processing = Object.fromEntries(
      Object.entries(this.processing || [])
    );
    delete processing.errors;

    const signer = Object.fromEntries(
      Object.entries(this.signer.getJson(true) || [])
    );
    delete signer.errors;
    const result = {
      templateId: templateID,
      documentName: `Application for ${this.businessDetails.dbaname}`,
      message: 'Thanks for doing bussines with us',
      templateData: [
        {
          ...businessDetails,
          ...this.contacts.getJson(),
          ...this.owners.getJson(true),
          ...this.banking.getJson(),
          ...processing,
          // ... this.attachments.getJson(),
          // ... this.services.getJson(),
          ...signer,
          orgId: orgID,
          templateId: linkId,
        },
      ],
      signer: {
        email: this.signer.email,
        firstName: this.signer.name.split(' ')[0],
        lastName: this.signer.name.split(' ')[1],
      },
    };
    return result;
  }

  /**
   * The function returns a promise that resolves to the result of the create function in the boarding
   * service
   * @returns A promise.
   */
  saveApplication(): Promise<any> {
    return this.boardingService.create('token', JSON.stringify(this));
  }
}

const boardingStore = new BoardingStore();
export default boardingStore;
